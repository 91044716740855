import React, { Component } from "react";
import "./Article.css";

import Nav from "../../navigation/Nav";

class Article extends Component {
  componentDidMount() {
    if (this.$ref && window.location.href.includes("#skills")) {
      this.$ref.scrollIntoView({
        // optional params
        behaviour: "smooth",
        block: "end",
        inline: "start"
      });
    }
  }
  render() {
    return (
      <>
        <Nav />
        <div className="article-container">
          <div className="article-header">
            <div className="header-title">
              <h1>
                <span className="header-title-first">React </span>
                <span className="header-title-second">burger menu</span>
              </h1>
            </div>
          </div>
          <div className="parent">
            <div className="left">
              Everyone knows a hamburger button, right? The famous three stripes that allow you to hide the menu behind the screen. It is especially in demand on mobile devices, where every pixel of empty space is expensive.
              Whether you like it or not, the hamburger menu is used and will be used for some time to come. The problem is how to implement it on React’s? Of course, everything looks simple and clear, but there are many subtleties. For example, is it worth adding a label? Which side is better to show - left or right? Is it better to use SVG, font, unicode character or pure CSS?
              I wanted to add a hamburger to my application, but could not find anything suitable. Most menus are either part of a framework like Material Design for Bootstrap or just too complex for a small application (example react-burger-menu). When I just wanted a button and a rolling panel.
              Therefore, today we will make our own hamburger with a sidebar. You are ready? Then let's go!
              <br />
              <h2>Beginning</h2>

              <p>
                Create a new project using create-react-app.
              </p>
              <div className="script-body" data-language="shellscript">
                <pre class="language-shellscript"><code class="language-shellscript">
                  create-react-app hamburger-demo <br />
                cd hamburger-demo <br />
                npm install --save styled-components prop-types</code></pre></div>
              <h2>Create a context</h2>
              <p>So, the first task we are faced with is how to transfer the message about
              the onClick event to another component? Of course, we can store the  <b> isMenuOpen </b>
              status in the parent component and pass the callback to the child. But in my opinion
                it is much easier and more logical to use context for these purposes.</p>
              <p>The context will pass two values:</p>
              <ul>
                <li>The boolean variable <b> isMenuOpen </b>, to inform the components about the status.</li>
                <li>ToggleMenuMode function to switch the status of the menu Open / menu Closed.</li>
              </ul>
              <p>Create the src / context / navState.js file with the following contents:</p>

              <div className="script-body">
                <pre class="language-shellscript">
                  <code class="language-shellscript">
                    import React,&#123;createContext, useState&#125; from 'react';
                  <br />import PropTypes from 'prop-types';
                  <br />export const MenuContext = createContext(&#123;
                  <br />  isMenuOpen: true,
                  <br />  toggleMenu: () 	&#61;&#62; &#123;&#125;,
                  <br /> &#125;);
                  <br />
                  const NavState = (&#123; children &#125;) &#61;&#62; &#123;
                  <br />  const [isMenuOpen, toggleMenu] = useState(false);
                  <br />
                  function toggleMenuMode() &#123;
                  <br />  toggleMenu(!isMenuOpen);
                  <br />&#125;
                  <br />
                  return (
                  &#62;MenuContext.Provider value=&#123;&#123;
                  <br /> isMenuOpen, toggleMenuMode &#125;&#125;&#62;&#123;children&#125; &#61;/MenuContext.Provider&#62;
                  <br />   );
                  <br /> &#125;;
                  <br />
                  NavState.propTypes = &#123;
                    children: PropTypes.node.isRequired,
                  &#125;;
                  <br />
                  export default NavState;
                </code></pre>
              </div>

              <img />
              <h2>Hamburger Button</h2>
              <p>Now, after we have created the context, we can proceed to the button itself.
                 The icon of the burger consists of 3 <b >span </b> elements. When hovering, we simply
                  change the length of the upper and lower strips. When the user clicks on the
                   button, we call the <b> toggleMenuMode() </b> function, which will switch the state
                    of <b> isMenuOpen</b>.</p>
              <p>If <b> isMenuOpen </b> is true, then the button is assigned the <b> active </b> class. The active state
                 turns the button into a cross, which is achieved by two transformations - a rotation
                  of 45 degrees and movement of several pixels. At the same time, the middle strip
                   is hiding.</p>
              <p>Create a new <b>src/components/HamburgerButton.js </b> file and add the following code:</p>
              <div className="script-body" data-language="shellscript">
                <pre class="language-shellscript"><code class="language-shellscript">
                  import React, &#123; useContext &#125; from 'react';
                <br />
                import styled from 'styled-components';
                <br />
                import &#123; MenuContext &#125; from '../context/navState';
                <br />
                  <br />const MenuButton = styled.button`
                <br />display: block;
                <br />transform-origin: 16px 11px;
                <br />float: left;
                <br />margin-right: 29px;
                <br />outline: 0;
                <br />border: 0;
                <br />padding: 12px;
                <br />background: none;
                <br />
                  <br />span &#123;
                <br />transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                <br />&#125;
                <br />
                  <br />:focus &#123;
                <br />border: medium none rgb(111, 255, 176);
                <br />box-shadow: rgb(111, 255, 176) 0 0 2px 2px;
                <br />outline: 0;
                <br />&#125;
                <br />
                  <br />:hover &#123;
                <br />span:nth-of-type(1) &#123;
                <br />width: 33px;
                <br />&#125;
                    <br />
                  <br />span:nth-of-type(2) &#123;
                <br />width: 40px;
                <br />&#125;
                <br />
                  <br />span:nth-of-type(3) &#123;
                <br />width: 30px;
                <br />&#125;
                <br />&#125;
                <br />
                  <br />&.active &#123;
                <br /> span:nth-of-type(1) &#123;
                <br />transform: rotate(45deg) translate(10px, 10px);
                <br />width: 40px;
                <br />&#125;
                    <br />
                  <br />span:nth-of-type(2) &#123;
                <br />opacity: 0;
                <br />pointer-events: none;
                <br />&#125;
                <br />
                  <br />span:nth-of-type(3) &#123;
                <br />transform: rotate(-45deg) translate(7px, -7px);
                <br />width: 40px;
                <br />&#125;
                <br />&#125;
                <br />`;
                    <br />
                  <br />const Bar = styled.span`
                <br />display: block;
                <br />width: 40px;
                <br />height: 5px;
                <br />margin-bottom: 7px;
                <br />background-color: #fff;
                <br />`;



                </code></pre></div>
              <h2>Coming out menu</h2>
              <p>We already have a hamburger button, the only thing left is to add the menu itself.
              It consists of two components - the menu and links. The menu is hidden using a simple
              trick with transform: translateX (-100%) ;. Thus, by default, the menu is hidden behind
                   the left edge of the screen, but leaves as soon as we change the value of translateX to 0.</p>
              <br />
              <p>For links, I put an arrow in the background, it can be downloaded from the repository.
              When you move the cursor, the background position changes, thus creating the effect of
                  the “movement” of the arrow.</p>
              <br />
              <p>Create the <b>src/components/SideMenu.js</b> file with the following contents:</p>
              <div className="script-body" data-language="shellscript">
                <pre class="language-shellscript"><code class="language-shellscript">
                  import React, &#123; useContext &#125; from 'react';
                  <br />import PropTypes from 'prop-types';
                  <br />import styled, &#123; css &#125; from 'styled-components';
                  <br />import &#123; MenuContext &#125; from '../context/navState';
                  <br />import arrow from '../arrow.svg';
                  <br />npm install --save styled-components prop-types
                  <br />
                  <br />const Menu = styled.nav`
                  <br />position: absolute;
                  <br />top: 0px;
                  <br />left: 0px;
                  <br />bottom: 0px;
                  <br />z-index: 293;
                  <br />display: block;
                  <br />width: 400px;
                  <br />max-width: 100%;
                  <br />margin-top: 0px;
                  <br />padding-top: 100px;
                  <br />padding-right: 0px;
                  <br />align-items: stretch;
                  <br />background-color: #001698;
                  <br />transform: translateX(-100%);
                  <br />transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                  <br />
                  <br />  $&#123;props &#61;&#62;
                  <br /> props.open &&
                  <br />css`
                  <br />transform: translateX(0);
                  <br />`&#125;
                  <br />`;
                  <br />
                  <br />export const MenuLink = styled.a`
                  <br />position: relative;
                  <br />display: block;
                  <br />text-align: left;
                  <br />max-width: 100%;
                  <br />padding-top: 25px;
                  <br />padding-bottom: 25px;
                  <br />padding-left: 16%;
                  <br />background-image: url(&#123;arrow&#125;);
                  <br />background-position: 88% 50%;
                  <br />background-size: 36px;
                  <br />background-repeat: no-repeat;
                  <br />transition: background-position 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
                  <br />text-decoration: none;
                  <br />color: #fff;
                  <br />font-size: 32px;
                  <br />line-height: 120%;
                  <br />font-weight: 500;
                  <br />
                  <br />:hover &#123;
                    <br />background-position: 90% 50%;
                  <br />&#125;
                  <br />`;
                  <br />
                  <br />export const SideMenu = (&#123;children&#125;) =&#62; &#123;
                    <br />const &#123;isMenuOpen&#125; = useContext(MenuContext);

                  <br />return &#60;Menu open=&#123;isMenuOpen&#125;&#62;&#123;children&#125;&#60;/Menu&#62;;
                  <br />&#125;;
                  <br />
                  <br />SideMenu.propTypes = &#123;
                  <br />children: PropTypes.node,
                  <br />&#125;;
                  <br />
                  <br />SideMenu.defaultProps = &#123;
                    <br />children: (
                  <br />&#60;&#62;
                    <br />&#60;MenuLink href="/"&#62;Home&#60;/MenuLink&#62;
                    <br />&#60;MenuLink href="/articles"&#62;Articles&#60;/MenuLink&#62;
                    <br />&#60;MenuLink href="/about"&#62;About&#60;/MenuLink&#62;
                    <br />&#60;MenuLink href="/contact"&#62;Contact&#60;/MenuLink&#62;
                    <br />&#60;/&#62;
                  <br />),
                  <br />&#125;;
                </code> </pre></div>
              <br />
              <h2>Navbar</h2>
              <p>There remains the last component, navbar. The <b> useOnClickOutside </b> function will be discussed
                 below, but for now, let's leave it as it is.</p>
              <p>src/components/MainMenu.js</p>
              <div className="script-body" data-language="shellscript">
                <pre class="language-shellscript"><code class="language-shellscript">
                  <br />import React, &#123; useRef, useContext &#125; from 'react';
                <br />import styled from 'styled-components';
                <br />import useOnClickOutside from '../hooks/onClickOutside';
                <br />import &#123; MenuContext &#125; from '../context/navState';
                <br />import HamburgerButton from './HamburgerButton';
                <br />import &#123; SideMenu &#125; from './SideMenu';
                <br />
                  <br />const Navbar = styled.div`
                <br />display: flex;
                <br />position: fixed;
                <br />left: 0;
                <br />right: 0;
                <br />box-sizing: border-box;
                <br />outline: currentcolor none medium;
                <br />max-width: 100%;
                <br />margin: 0px;
                <br />align-items: center;
                <br />background: #082bff none repeat scroll 0% 0%;
                <br />color: rgb(248, 248, 248);
                <br />min-width: 0px;
                <br />min-height: 0px;
                <br />flex-direction: row;
                <br />justify-content: flex-start;
                <br />padding: 6px 60px;
                <br />box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 16px;
                <br />z-index: 500;
                <br />`;
                <br />
                  <br />const MainMenu = () =&#62; &#123;
                <br />const node = useRef();
                <br />const &#123; isMenuOpen, toggleMenuMode &#125; = useContext(MenuContext);
                <br />useOnClickOutside(node, () =&#62; &#123;
                  <br />// Only if menu is open
                  <br />if (isMenuOpen) &#123;
                    <br />toggleMenuMode();
                    <br />&#125;
                    <br />&#125;);
                    <br />
                  <br />  return (
                      <br /> &#60;header ref=&#123;node&#125;&#62;
                      <br /> &#60;Navbar&#62;
                    <br /> &#60;HamburgerButton /&#62;
                    <br /> &#60;h1&#62;Website&#60;/h1&#62;
                  <br /> &#60;/Navbar&#62;
                  <br /> &#60;SideMenu /&#62;
                  <br /> &#60;/header&#62;
                  <br /> );
                      <br /> &#125;;
                      <br />
                  <br /> export default MainMenu;
                </code></pre></div>
              <br />
              <h2>Putting it all together</h2>
              <p>All components are ready, it remains only to correctly call it all. In the <b> src/App.js </b> file should be:</p>
              <div className="script-body" data-language="shellscript">
                <pre class="language-shellscript"><code class="language-shellscript">
                  import React from 'react';
                  <br />import NavState from './context/navState';
                  <br />import MainMenu from './components/MainMenu';
                  <br />
                  <br />function App() &#123;
                    <br />return (
    <br />&#60;NavState&#62;
                    <br />&#60;MainMenu /&#62;
                    <br />&#60;/NavState&#62;
                  <br />);
<br />&#125;
<br />
                  <br />export default App;
</code></pre></div>
              <p>And the last auxiliary function, when the user clicks the mouse outside the
              navbar / menu, the menu should automatically hide. How to do it? React’s
              has a <b>useRef()</b> hook that allows you to access the DOM directly.
              Thus, when you click, you can check whether the click falls into
                   a given ref or not.</p>
              <p>The return  code will work when the component is removed, in order to avoid memory leaks,
              the listener is deleted. At the end, the dependencies [ref, handler] are indicated in square brackets;
                when updating the ref, the listener will also be updated.</p>
              <h2>src/hooks/onClickOutside.js</h2>
              <div className="script-body" data-language="shellscript">
                <pre class="language-shellscript"><code class="language-shellscript">
                  import &#123;useEffect&#125; from 'react';
                <br />
                  <br />const useOnClickOutside = (ref, handler) =&#62; &#123;
                    <br />useEffect(() =&#60; &#123;
                    <br />const listener = event =&#60; &#123;
                    <br />if (!ref.current || ref.current.contains(event.target)) &#123;
                    <br />return;
                <br />&#125;
                <br />handler(event);
                <br />&#125;;
                <br />document.addEventListener('mousedown', listener);
                <br />return () =&#62; &#123;
                    <br />document.removeEventListener('mousedown', listener);
                <br />&#125;;
                <br />&#125;, [ref, handler]);
                <br />&#125;;
                <br />
                  <br />export default useOnClickOutside;
                </code></pre></div>
            </div>
            <div className="right">
              <b>
                <a href="#">React burger menu</a>
              </b>
              {/* <a href="#">Great tool "CSS Used" </a> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Article;
