import React, { Component } from "react";
import Home from "./../home/Home";
import Projects from "./../projects/Projects";
import Blog from "./../blog/Blog";
import Nav from "./../navigation/Nav";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import Article from "./../blog/articles/Article";
import Article1 from "./../blog/articles/Article1";
import {
  HashLink as Link
} from "react-router-hash-link";
import Certificates from "./../certificates/Certificates";
import { render } from "@testing-library/react";
import { SectionsContainer, Section } from 'react-fullpage';
// import ReactPageScroller from "react-page-scroller";
class Parent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value_key: "",
      value_bottom: "",
      projects_top: "",
      projects_bottom: ""
    }
  }
  parentFunction = (data_from_child_top, data_from_child_bottom) => {
    this.setState({ value_key: data_from_child_top, value_bottom: data_from_child_bottom });
  }
  parentFunctionForProjects = (data_from_child_top, data_from_child_bottom) => {
    this.setState({ projects_top: data_from_child_top, projects_bottom: data_from_child_bottom });
    console.log('PARENT PROJECT TOP ' + this.state.projects_top);
  }
  render() {
    return (
      <React.Fragment >
        <Router basename="/" >
          <div > {
            /*
                      A <Switch> looks through all its children <Route>
                      elements and renders the first one whose path
                      matches the current URL. Use a <Switch> any time
                      you have multiple routes, but you want only one
                      of them to render at a time
                    */
          }
            <Switch >

              {/* <ReactPageScroller ref={c => this.reactPageScroller = c}> */}
              <Route exact path="/" >
                <Nav valueFromParent={this.state.value_key} valueFromParentBottom={this.state.value_bottom}
                  valueFromProjectTop={this.state.projects_top} valueFromProjectBottom={this.state.projects_bottom}
                />
                <Home />
                <Projects functionCallFromParentForProject={this.parentFunctionForProjects.bind(this)} />
                <Certificates functionCallFromParent={this.parentFunction.bind(this)} />
                <Blog />
              </Route>


              <Route path="/#section-projects" >
                <Nav valueFromParent={this.state.value_key} valueFromParentBottom={this.state.value_bottom} />
                <Home />
                <Projects functionCallFromParentForProject={this.parentFunctionForProjects.bind(this)} />
                <Certificates functionCallFromParent={this.parentFunction.bind(this)} />
                <Blog />
              </Route>


              <Route path="/#section-certifications" >
                <Nav valueFromParent={this.state.value_key} valueFromParentBottom={this.state.value_bottom} />
                <Home />
                <Projects functionCallFromParentForProject={this.parentFunctionForProjects.bind(this)} />
                <Certificates functionCallFromParent={this.parentFunction.bind(this)} />
                <Blog />
              </Route>

              <Route path="/blog/react-adaptive-menu" >
                <Article1 />
              </Route>
              <Route path="/blog/react-burger-menu" >
                <Article />
              </Route>
              {/* </ReactPageScroller> */}
            </Switch >

          </div>
        </Router >
      </React.Fragment >


    );
  }
};


export default Parent;

