import React, {
  Component
} from "react";
import "./Certificates.css";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  render
} from "@testing-library/react";
import Nav from "./../navigation/Nav";


class Certificates extends Component {
  constructor(props) {
    super(props);
    let certTopIndent = "";
    let certBottomIndent = "";
  }
  componentDidMount() {
    window.addEventListener("load", this.childFunction.bind(this));
    AOS.init({
      duration: 1000
    })
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("load", this.childFunction.bind(this));
  }
  // Returns the value of section top indent to parent and then from parent to Nav
  childFunction = (e) => {
    e.preventDefault();
    this.props.functionCallFromParent(this.certTopIndent, this.certBottomIndent);
  }
  render() {
    return (
      <section className="certificates" id="section-certifications"
        ref={el => {
          if (!el) return;
          let nextValue = JSON.stringify(el.getBoundingClientRect());
          // console.log('top ' + JSON.parse(nextValue)['top']);
          this.certTopIndent = JSON.parse(nextValue)['top'];
          this.certBottomIndent = JSON.parse(nextValue)['bottom'];
        }
        }
      >
        <div className="container_cert">
          {/* data-aos="flip-left" */}
          <div className="card_cert" data-aos="fade-up">
            <div className="card_cert_inner">
              <div className="imgBx " data-text="CCNA">
                <img src="/images/cisco_c.png" alt="" />
              </div>
              <div className="content">
                <div className="content_inner">
                  <h3>CCNA</h3>
                  <p>Network fundamentals, Network access, IP connectivity, IP services, Security fundamentals,
                        Automation and programmability</p>
                  <a href="#">Active to 03/03/2021</a>
                </div>
              </div>
            </div>
          </div>
          {/* data-aos="flip-left" */}
          <div className="card_cert" data-aos="fade-up">
            <div className="card_cert_inner">
              <div className="imgBx" data-text="CCNA Security">
                <img src="/images/cisco_c.png" alt="" />
              </div>
              <div className="content">
                <div className="content_inner">
                  <h3>CCNA Security</h3>
                  <p>Installation, troubleshooting and monitoring of network devices to maintain integrity,
                        confidentiality and availability of data and devices</p>
                  <a href="#">Preparing for the exam</a>
                </div>
              </div>
            </div>
          </div>
          <div className="card_cert" data-aos="fade-up">
            <div className="card_cert_inner">
              <div className="imgBx" data-text="PWAs">
                <img src="/images/google_c.png" alt="" />
              </div>
              <div className="content">
                <div className="content_inner">
                  <h3>Developing Progressive Web Apps</h3>
                  <p>Front end networking, Progressive Web Apps, Performance optimization and caching, Testing and
                        debugging, Mobile web forms</p>
                  <a href="#">Preparing for the exam</a>
                </div>
              </div>
            </div>
          </div>
          <div className="card_cert" data-aos="fade-up">
            <div className="card_cert_inner">
              <div className="imgBx" data-text="WEB Design">
                <img src="/images/university_c.png" alt="" />
              </div>
              <div className="content">
                <div className="content_inner">
                  <h3>WEB Design</h3>
                  <p>HTML, CSS, LeSS, SaSS, JS, React, Angular, MVC, PHP,DB, MySQL, MangoDB, Photoshop, Axure, Github,
                        Codepen, UI/UX, Bootstrap, Firebase</p>
                  <a href="#">In the process</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    );
  }
}
export default Certificates;