import React, { Component } from "react";
import "./Article.css";

import Nav from "../../navigation/Nav";

class Article extends Component {
  componentDidMount() {
    if (this.$ref && window.location.href.includes("#skills")) {
      this.$ref.scrollIntoView({
        // optional params
        behaviour: "smooth",
        block: "end",
        inline: "start"
      });
    }
  }
  render() {
    return (
      <>
        <Nav />
        <div className="article-container">
          <div className="article-header">
            <div className="header-title">
              <h1>
                <span className="header-title-first">React adaptive</span>
                <span className="header-title-second">Menu</span>
              </h1>
            </div>
          </div>
          <div className="parent">
            <div className="left">
              This tutorial will take you through some of the most important
              concepts in React Router by showing how to build a simple
              portfolio website that uses the library to navigate through its
              pages. Single Page Apps (SPAs) are all the rave these days. Unlike
              a traditional web application, an SPA dynamically rewrites the
              current page instead of requesting entirely new pages from the
              server. This makes web apps behave more like a desktop or mobile
              applications. In order to make this work, a way to distinguish
              between each page of the application is needed and this is
              typically achieved by using a router. In the React ecosystem,
              React Router is the most popular choice for implementing routing.
            </div>
            <div className="right">
              <b>
                <a href="#">React adaptive Menu</a>
              </b>
              <a href="#">Do not stop learn react</a>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Article;
