import React, {
  Component
} from "react";
import "./Nav1.css";
import classnames from "classnames";
import {
  render
} from "@testing-library/react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

import {
  HashLink as Link
} from "react-router-hash-link";


class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: false,
      home: false,
      certification: false,
      projects: false,
      blog: false,
      textDisplay: false,
      tempTop: ""
    };
  }

  toggleButton = () => {
    console.log('STATE ', this.state.textDisplay);
    this.setState((currentState) => ({
      textDisplay: !currentState.textDisplay
    }))
  }
  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    // console.log('NAVBAR CERTIFICATES TOP' + this.props.valueFromParent);
    // console.log('NAVBAR PROJECT TOP' + this.props.valueFromProjectTop);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  // Hide or show the menu.
  handleScroll = () => {
    const {
      prevScrollpos
    } = this.state;
    const currentScrollPos = window.pageYOffset;
    // const visible = prevScrollpos < currentScrollPos;
    const visible = 0 < currentScrollPos;
    const home = 0 < currentScrollPos && currentScrollPos < this.props.valueFromProjectTop;
    const projects = this.props.valueFromProjectTop <= currentScrollPos && currentScrollPos < this.props.valueFromProjectBottom;
    const certification = this.props.valueFromParent < currentScrollPos && currentScrollPos < this.props.valueFromParentBottom;
    const blog = this.props.valueFromParentBottom <= currentScrollPos && currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
      home,
      projects,
      certification,
      blog
    });
  };




  render() {
    return (
      <nav id="mainNav" className={classnames("navbar navbar-expand-lg navbar-dark ", {
        "navbar-scrolled": this.state.visible


      })}
      >
        <div className="container" >
          <Link smooth to="/#home-header" className={classnames("navbar-brand", {
            active: this.state.home,
            blackOnWhite: this.state.projects || this.state.blog

          })
          }></Link>

          <button className="navbar-toggler"
            type="button" onClick={this.toggleButton}
          >
            <span className="navbar-toggler-icon" > </span>
          </button >
          <div className={this.state.textDisplay ? "collapse navbar-collapse show" : "collapse navbar-collapse"} id="navbarNav" >
            <ul className="navbar-nav" > {
            }
              <li className={this.state.textDisplay ? "nav-item lifloat" : "nav-item"}>
                <Link smooth to="/#section-projects" className={
                  classnames("nav-link", {
                    active: this.state.projects,
                    blackOnWhite: this.state.projects || this.state.blog
                  })
                } >
                  Projects
              </Link>
              </li >
              <li className={this.state.textDisplay ? "nav-item lifloat" : "nav-item"}>
                <Link smooth to="/#section-certifications" className={
                  classnames("nav-link", {
                    active: this.state.certification,
                    blackOnWhite: this.state.projects || this.state.blog
                  })
                } >
                  Certifications
                </Link>
              </li >
              <li className={this.state.textDisplay ? "nav-item lifloat" : "nav-item"}>
                <Link smooth to="/#section-blog"
                  className={
                    classnames("nav-link", {
                      active: this.state.blog,
                      blackOnWhite: this.state.projects || this.state.blog
                    })
                  } >
                  Blog
              </Link>
              </li >
            </ul>
            <ul className="navbar-nav ml-auto" >
              <li className={this.state.textDisplay ? "nav-item lifloat" : "nav-item"}>
                <a href="https://agorbulin.com/download.php"
                  target="_blank"
                  className={classnames("nav-link", {
                    blackOnWhite: this.state.projects || this.state.blog
                  })
                  }
                >
                  Resume
                </a>
              </li >
              <li className={this.state.textDisplay ? "nav-item lifloat" : "nav-item"}>
                <a className={classnames("nav-link", {
                  blackOnWhite: this.state.projects || this.state.blog
                })
                }
                  href="https://linkedin.com/in/aleksandr-gorbulin-b0b37a66"
                  target="_blank" >
                  LinkedIn </a>
              </li >
              <li className={this.state.textDisplay ? "nav-item lifloat" : "nav-item"}>
                <a className={classnames("nav-link", {
                  blackOnWhite: this.state.projects || this.state.blog
                })
                }
                  href="https://codepen.io/AleksGorbulin"
                  target="_blank" >
                  Codepen </a>
              </li >

              <li className={this.state.textDisplay ? "nav-item lifloat" : "nav-item"}>
                <a className={classnames("nav-link", {
                  blackOnWhite: this.state.projects || this.state.blog
                })
                }
                  href="https://github.com/sany777"
                  target="_blank" >
                  git
                </a>
              </li >
              <li className={this.state.textDisplay ? "nav-item lifloat" : "nav-item"}>
                <a className={classnames("nav-link", {
                  blackOnWhite: this.state.projects || this.state.blog
                })
                }
                  href="mailto:Aleksgorbulin@gmail.com"
                  target="_blank" >
                  Email </a>
              </li > </ul>
          </div >



        </div>
      </nav >
    );
  }
}
export default Nav;