import React, { Component } from "react";
import "./Skills.css";
import { render } from "@testing-library/react";
import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

class Skills extends Component {
  constructor() {
    super();
    this.state = { value: 0 };
    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    this.setState({ value });
  }
  showBar = x => {
    return "width:" + x + "%";
  };

  render() {
    return (
      <section className="section-about" ref={this.skill}>
        <div className="u-center-text about-u-margin-bottom-big">
          <h2 className="heading-secondary" id="section-skills">
            Skills and work examples
          </h2>
        </div>

        <div className="row">
          <div className="col-1-of-2 skills-left-side">
            <div className="skills">
              <ul className="lines">
                <li className="line l--0">
                  <span className="line__label title">Skill level:</span>
                </li>
                <li className="line l--25">
                  <span className="line__label">The basics</span>
                </li>
                <li className="line l--50">
                  <span className="line__label">Seasoned</span>
                </li>
                <li className="line l--75">
                  <span className="line__label">Advanced</span>
                </li>
                <li className="line l--100">
                  <span className="line__label">Expert</span>
                </li>
              </ul>

              <div className="charts">
                <div className="chart chart--dev">
                  <span className="chart__title">Skills</span>
                  <ul className="chart--horiz">
                    <li
                      className="chart__bar"
                      style={this.showBar(60).bind}
                      onClick={e => this.onChange(parseInt(0))}
                    >
                      <span className="chart__label">HTML5</span>
                    </li>
                    <li
                      className="chart__bar"
                      style={{ width: "90%" }}
                      onClick={e => this.onChange(parseInt(1))}
                    >
                      <span className="chart__label">CSS3 & SCSS</span>
                    </li>
                    <li
                      className="chart__bar"
                      style={{ width: "70%" }}
                      onClick={e => this.onChange(parseInt(2))}
                    >
                      <span className="chart__label">JavaScript</span>
                    </li>
                    <li
                      className="chart__bar"
                      style={{ width: "40%" }}
                      onClick={e => this.onChange(parseInt(3))}
                    >
                      <span className="chart__label">AngularJS</span>
                    </li>
                    <li className="chart__bar" style={{ width: "40%" }}>
                      <span className="chart__label">ReactJS</span>
                    </li>
                    <li className="chart__bar" style={{ width: "60%" }}>
                      <span className="chart__label">jQuery</span>
                    </li>
                    <li className="chart__bar" style={{ width: "50%" }}>
                      <span className="chart__label">NodeJS</span>
                    </li>

                    <li className="chart__bar" style={{ width: "70%" }}>
                      <span className="chart__label">.NET</span>
                    </li>
                    <li className="chart__bar" style={{ width: "75%" }}>
                      <span className="chart__label">
                        Git + Github, Bitbucket & Sourcetree
                      </span>
                    </li>
                    <li className="chart__bar" style={{ width: "45%" }}>
                      <span className="chart__label">Photoshop</span>
                    </li>
                    {/* <li className="chart__bar" style={{ width: "35%" }}>
                      <span className="chart__label">Illustrator</span>
                    </li> */}
                    <li className="chart__bar" style={{ width: "60%" }}>
                      <span className="chart__label">User Experience</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-1-of-2">
            {/* <div className="composition">
              <img
                alt="Photo 1"
                className="composition__photo composition__photo--p1"
                src="/images/macbook.jpg"
              />

              <img
                sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                alt="Photo 2"
                className="composition__photo composition__photo--p2"
                src="/images/macbook.jpg"
              />
        
              <img
                alt="Photo 3"
                className="composition__photo composition__photo--p3"
                src="/images/macbook.jpg"
              />
            </div> */}
            <input
              type="number"
              value={this.state.value}
              onChange={e => this.onChange(parseInt(e.target.value || 0))}
            />
            <Carousel
              value={this.state.value}
              onChange={this.onChange}
              slides={[
                <img src="/images/skills/html.jpg" />,
                <img src="/images/skills/css.jpg" />,
                <img src="/images/skills/js.jpg" />,
                <img src="/images/skills/angular.jpg" />
              ]}
              arrows
              clickToChange
            />
          </div>
        </div>
      </section>
    );
  }
}
export default Skills;
