import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Home from "./components/home/Home";
import Projects from "./components/projects/Projects";
// import Certificates from "./components/certificates/Certificates";
import Skills from "./components/skills/Skills";
import Blog from "./components/blog/Blog";
import Nav from "./components/navigation/Nav";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import Article from "./components/blog/articles/Article";
import Article1 from "./components/blog/articles/Article1";
import {
  HashLink as Link
} from "react-router-hash-link";
import Certificates from "./components/certificates/Certificates";
import { render } from "@testing-library/react";
import Parent from "./components/parent/Parent";

function App() {
  return (
    <Parent />
  );
};


export default App;