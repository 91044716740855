import React, { Component } from "react";
// import { browserHistory, Router, Route } from "react-router";
import "./Blog.css";
import { render } from "@testing-library/react";
import Article from "./articles/Article";
import Article1 from "./articles/Article1";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

class Blog extends Component {
  render() {
    return (
      <>
        <section className="section-stories" id="section-blog">
          <div className="bg-video">
            <video className="bg-video__content" loop autoPlay muted playsInline>
              <source src="/images/coding.mp4" type="video/mp4" />
              <source src="/images/video.webm" type="video/webm" />
              Your browser is not supported!
            </video>
          </div>

          {/* <div className="u-center-text u-margin-bottom-big stories-heading">
            <h2 className="heading-secondary white">Blog</h2>
          </div> */}

          <div className="row">
            <div className="col-1-of-2">
              <div className="story">
                <figure className="story__shape">
                  <img
                    src="images/rexchangeData.gif"
                    alt="Person on a tour"
                    className="story__img adjustBlogImage"
                  />
                  <figcaption className="story__caption">
                    Not Published Yet
                  </figcaption>
                </figure>
                <div className="story__text">
                  <h3 className="heading-tertiary u-margin-bottom-small">
                    Great tool "CSS USED"
                  </h3>
                  <p>
                    Were you ever think about to style you website like a bootstap or material design,
                    but did not know all the css that is used there...
                    {/* <Link to="/blog/react-adaptive-menu">Read more</Link> */}
                  </p>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="col-1-of-2">
              <div className="story">
                <figure className="story__shape">
                  <img
                    src="images/burger_menu.gif"
                    alt="Person on a tour"
                    className="story__img"

                  />
                  <figcaption className="story__caption">
                    Published 02/01/2020
                  </figcaption>
                </figure>
                <div className="story__text">
                  <h3 className="heading-tertiary u-margin-bottom-small">
                    React
                    <br /> burger menu
                  </h3>
                  <p>
                    Everyone knows a hamburger button, right?
                    The famous three stripes that allow you to hide ...
                    <Link to="/blog/react-burger-menu">Read more</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="u-center-text u-margin-top-huge">

          </div> */}
        </section>
      </>
    );
  }
}
export default Blog;
