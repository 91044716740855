import React, { Component } from "react";
import "./Home.css";


class Home extends Component {
  componentDidMount() {

    if (this.$ref && window.location.href.includes("#skills")) {
      this.$ref.scrollIntoView({
        // optional params
        behaviour: "smooth",
        block: "end",
        inline: "start"
      });
    }
  }
  render() {
    return (
      <header className="header" id="home-header">
        <div className="header__text-box">
          <h1 className="heading-primary">
            <span className="heading-primary--main">Portfolio</span>
            <span className="heading-primary--sub">of Alex Gorbulin</span>
          </h1>
        </div>

        <a href="#section-projects" >
          <span className="hero__scroll" >
            Scroll down <br />
            <i className="chevron bottom"></i>
          </span>
        </a>

      </header>
    );
  }
}
export default Home;
